<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    :disabledSaveButton="isEqual"
    @save="onSaveForm()"
    @cancel="onCancel('SupportRoutes')"
    class="support-route-edit"
  >
    <template #checklist>
      <div class="checklist">
        <div
          v-for="(item, index) in mainChecklistItems"
          :key="item.id"
          class="checklist__item"
        >
          <span>{{ index + 1 }}.</span>
          <sm-input v-model="item.name" type="text" />
          <button
            v-show="mainChecklistItems.length"
            class="checklist__delete-button"
            @click="openDeleteChecklistModal(item.id)"
          >
            <sm-icon name="trash" />
          </button>
        </div>
        <button class="checklist__add-button" @click="onAddChecklist()">
          <sm-icon name="plus" />
          Добавить пункт
        </button>
      </div>
    </template>

    <template #group-top-panel-requestFields1>
      <radio-buttons
        v-model="selectedTabForRequestFields1"
        :buttons="radioButtons"
        class="support-route-edit__radio-buttons"
      ></radio-buttons>
    </template>
    <template
      v-if="selectedTabForRequestFields1 === radioButtons[1].id"
      #group-body-requestFields1
    >
      <div class="checklist">
        <div
          v-for="(item, index) in checklistItemsForRequest1"
          :key="item.id"
          class="checklist__item"
        >
          <span>{{ index + 1 }}.</span>
          <sm-input v-model="item.name" type="text" />
          <button
            v-show="checklistItemsForRequest1.length"
            class="checklist__delete-button"
            @click="openDeleteChecklistModal(item.id)"
          >
            <sm-icon name="trash" />
          </button>
        </div>
        <button class="checklist__add-button" @click="onAddChecklist(1)">
          <sm-icon name="plus" />
          Добавить пункт
        </button>
      </div>
    </template>

    <template #group-top-panel-requestFields2>
      <radio-buttons
        v-model="selectedTabForRequestFields2"
        :buttons="radioButtons"
        class="support-route-edit__radio-buttons"
      ></radio-buttons>
    </template>
    <template
      v-if="selectedTabForRequestFields2 === radioButtons[1].id"
      #group-body-requestFields2
    >
      <div class="checklist">
        <div
          v-for="(item, index) in checklistItemsForRequest2"
          :key="item.id"
          class="checklist__item"
        >
          <span>{{ index + 1 }}.</span>
          <sm-input v-model="item.name" type="text" />
          <button
            v-show="checklistItemsForRequest2.length"
            class="checklist__delete-button"
            @click="openDeleteChecklistModal(item.id)"
          >
            <sm-icon name="trash" />
          </button>
        </div>
        <button class="checklist__add-button" @click="onAddChecklist(2)">
          <sm-icon name="plus" />
          Добавить пункт
        </button>
      </div>
    </template>

    <template #group-top-panel-requestFields3>
      <radio-buttons
        v-model="selectedTabForRequestFields3"
        :buttons="radioButtons"
        class="support-route-edit__radio-buttons"
      ></radio-buttons>
    </template>
    <template
      v-if="selectedTabForRequestFields3 === radioButtons[1].id"
      #group-body-requestFields3
    >
      <div class="checklist">
        <div
          v-for="(item, index) in checklistItemsForRequest3"
          :key="item.id"
          class="checklist__item"
        >
          <span>{{ index + 1 }}.</span>
          <sm-input v-model="item.name" type="text" />
          <button
            v-show="checklistItemsForRequest3.length"
            class="checklist__delete-button"
            @click="openDeleteChecklistModal(item.id)"
          >
            <sm-icon name="trash" />
          </button>
        </div>
        <button class="checklist__add-button" @click="onAddChecklist(3)">
          <sm-icon name="plus" />
          Добавить пункт
        </button>
      </div>
    </template>

    <template #group-top-panel-requestFields4>
      <radio-buttons
        v-model="selectedTabForRequestFields4"
        :buttons="radioButtons"
        class="support-route-edit__radio-buttons"
      ></radio-buttons>
    </template>
    <template
      v-if="selectedTabForRequestFields4 === radioButtons[1].id"
      #group-body-requestFields4
    >
      <div class="checklist">
        <div
          v-for="(item, index) in checklistItemsForRequest4"
          :key="item.id"
          class="checklist__item"
        >
          <span>{{ index + 1 }}.</span>
          <sm-input v-model="item.name" type="text" />
          <button
            v-show="checklistItemsForRequest4.length"
            class="checklist__delete-button"
            @click="openDeleteChecklistModal(item.id)"
          >
            <sm-icon name="trash" />
          </button>
        </div>
        <button class="checklist__add-button" @click="onAddChecklist(4)">
          <sm-icon name="plus" />
          Добавить пункт
        </button>
      </div>
    </template>
  </sm-editable-item>
</template>

<script>
// mixins
import editableListItemEdit from '@/mixins/editableListItemEdit.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';
import SmInput from '@/components/common/forms/SmInput.vue';
import SmIcon from '@/components/common/SmIcon.vue';
import RadioButtons from '@/components/client-sites/RadioButtons.vue';

export default {
  name: 'SupportRouteEdit',

  components: {
    SmEditableItem,
    SmInput,
    SmIcon,
    RadioButtons,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'SupportRoutes',
      pageHeader: 'Редактирование маршрута',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Маршруты',
          route: { name: 'SupportRoutes' },
        },
        {
          text: 'Редактирование маршрута',
        },
      ],
      form: {},
      radioButtons: [
        { id: 'main', name: 'Основные' },
        { id: 'checklist', name: 'Чеклист' },
      ],
      selectedTabForRequestFields1: 'main',
      selectedTabForRequestFields2: 'main',
      selectedTabForRequestFields3: 'main',
      selectedTabForRequestFields4: 'main',
    };
  },

  computed: {
    ...mapState({
      item: (state) => state.editableList.item,
      gisTypes: (state) => state.common.gisTypes,
      initiationStatuses: (state) => state.common.initiationStatuses,
      performers: (state) => state.common.performers,
      pools: (state) => state.common.pools,
    }),

    fields() {
      const {
        moveRequestToYourself,
        choosePerformer,
        createRequest,
        createRequest2,
        createRequest3,
        createRequest4,
        createRequestForYourself1,
        createRequestForYourself2,
        createRequestForYourself3,
        createRequestForYourself4,
      } = this.form;
      const fields = [
        {
          tab: 'Основные',
          groups: [
            {
              groupTitle: '',
              form: [
                {
                  type: 'text',
                  key: 'actionName',
                  label: 'Название действия',
                },
                {
                  type: 'select',
                  key: 'gisTypeId',
                  label: 'Тип заявки',
                  list: this.gisTypes.data,
                },
                {
                  type: 'select',
                  key: 'gisTypeMoveId',
                  label: 'Тип перехода',
                  list: this.gisTypes.data,
                },
                {
                  type: 'select',
                  key: 'initiationStatusMoveId',
                  label: 'Статус перехода',
                  list: this.initiationStatuses.data,
                },
                {
                  type: 'select',
                  key: 'performerId',
                  label: 'Установить исполнителя',
                  list: this.performers.data,
                  disabled: choosePerformer || moveRequestToYourself,
                },
                {
                  type: 'select',
                  key: 'poolId',
                  label: 'Пул',
                  list: this.pools.data,
                  disabled: choosePerformer || moveRequestToYourself,
                },
              ],
            },
            {
              groupTitle: '',
              form: [
                {
                  type: 'checkbox',
                  key: 'performRequest',
                  label: 'Выполнить заявку',
                },
                {
                  type: 'checkbox',
                  key: 'moveRequestToWPool',
                  label: 'Отправить заявку в общий чат W',
                },
                {
                  type: 'checkbox',
                  key: 'choosePerformer',
                  label: 'Выбор сотрудника для передачи',
                },
                {
                  type: 'checkbox',
                  key: 'moveRequestToYourself',
                  label: 'Перевести заявку себе',
                },
                {
                  type: 'checkbox',
                  key: 'completeRequestByPerformer',
                  label: 'Фиксировать выполнение работ сотрудником',
                },
              ],
            },
            {
              groupTitle: '',
              form: [
                {
                  type: 'decimalNumber',
                  key: 'addPlanHours',
                  label: 'Добавлять плановых часов',
                  attributes: {
                    options: {
                      minimumValue: 0,
                      decimalPlaces: 2,
                      step: 1,
                      digitGroupSeparator: ' ',
                      emptyInputBehavior: 'null',
                    },
                  },
                },
                {
                  type: 'textarea',
                  key: 'addComment',
                  label: 'Добавить комментарий к задаче',
                },
              ],
            },
            {
              groupTitle: 'Заявки',
              form: [
                {
                  type: 'checkbox',
                  key: 'createRequest',
                  label: 'Создать заявки 1',
                },
                {
                  type: 'checkbox',
                  key: 'createRequest2',
                  label: 'Создать Заявки 2',
                },
                {
                  type: 'checkbox',
                  key: 'createRequest3',
                  label: 'Создать заявки 3',
                },
                {
                  type: 'checkbox',
                  key: 'createRequest4',
                  label: 'Создать заявки 4',
                },
              ],
            },
            {
              groupId: 'requestFields1',
              groupTitle: 'Поля заявки 1',
              form: [
                {
                  type: 'text',
                  key: 'createRequestText',
                  label: 'Название заявки',
                },
                {
                  type: 'select',
                  key: 'createRequestType',
                  label: 'Тип заявки',
                  list: this.gisTypes.data,
                },
                {
                  type: 'select',
                  key: 'createRequestPerformer',
                  label: 'Установить исполнителя',
                  list: this.performers.data,
                  disabled: createRequestForYourself1,
                },
                {
                  type: 'select',
                  key: 'createRequestPool',
                  label: 'Пул',
                  list: this.pools.data,
                  disabled: createRequestForYourself1,
                },
                {
                  type: 'checkbox',
                  key: 'createRequestForYourself1',
                  label: 'Создать заявку себе',
                },
                {
                  type: 'select',
                  key: 'createRequestStatus',
                  label: 'Статус при создании',
                  list: this.initiationStatuses.data,
                },
                {
                  type: 'textarea',
                  key: 'createRequestFirstComment',
                  label: 'Добавить комментаий к заявке',
                },
              ],
            },
            {
              groupId: 'requestFields2',
              groupTitle: 'Поля заявки 2',
              form: [
                {
                  type: 'text',
                  key: 'createRequestText2',
                  label: 'Название заявки',
                },
                {
                  type: 'select',
                  key: 'createRequestType2',
                  label: 'Тип заявки',
                  list: this.gisTypes.data,
                },
                {
                  type: 'select',
                  key: 'createRequestPerformer2',
                  label: 'Установить исполнителя',
                  list: this.performers.data,
                  disabled: createRequestForYourself2,
                },
                {
                  type: 'select',
                  key: 'createRequestPool2',
                  label: 'Пул',
                  list: this.pools.data,
                  disabled: createRequestForYourself2,
                },
                {
                  type: 'checkbox',
                  key: 'createRequestForYourself2',
                  label: 'Создать заявку себе',
                },
                {
                  type: 'select',
                  key: 'createRequestStatus2',
                  label: 'Статус при создании',
                  list: this.initiationStatuses.data,
                },
                {
                  type: 'textarea',
                  key: 'createRequestFirstComment2',
                  label: 'Добавить комментаий к заявке',
                },
              ],
            },
            {
              groupId: 'requestFields3',
              groupTitle: 'Поля заявки 3',
              form: [
                {
                  type: 'text',
                  key: 'createRequestText3',
                  label: 'Название заявки',
                },
                {
                  type: 'select',
                  key: 'createRequestType3',
                  label: 'Тип заявки',
                  list: this.gisTypes.data,
                },
                {
                  type: 'select',
                  key: 'createRequestPerformer3',
                  label: 'Установить исполнителя',
                  list: this.performers.data,
                  disabled: createRequestForYourself3,
                },
                {
                  type: 'select',
                  key: 'createRequestPool3',
                  label: 'Пул',
                  list: this.pools.data,
                  disabled: createRequestForYourself3,
                },
                {
                  type: 'checkbox',
                  key: 'createRequestForYourself3',
                  label: 'Создать заявку себе',
                },
                {
                  type: 'select',
                  key: 'createRequestStatus3',
                  label: 'Статус при создании',
                  list: this.initiationStatuses.data,
                },
                {
                  type: 'textarea',
                  key: 'createRequestFirstComment3',
                  label: 'Добавить комментаий к заявке',
                },
              ],
            },
            {
              groupId: 'requestFields4',
              groupTitle: 'Поля заявки 4',
              form: [
                {
                  type: 'text',
                  key: 'createRequestText4',
                  label: 'Название заявки',
                },
                {
                  type: 'select',
                  key: 'createRequestType4',
                  label: 'Тип заявки',
                  list: this.gisTypes.data,
                },
                {
                  type: 'select',
                  key: 'createRequestPerformer4',
                  label: 'Установить исполнителя',
                  list: this.performers.data,
                  disabled: createRequestForYourself4,
                },
                {
                  type: 'select',
                  key: 'createRequestPool4',
                  label: 'Пул',
                  list: this.pools.data,
                  disabled: createRequestForYourself4,
                },
                {
                  type: 'checkbox',
                  key: 'createRequestForYourself4',
                  label: 'Создать заявку себе',
                },
                {
                  type: 'select',
                  key: 'createRequestStatus4',
                  label: 'Статус при создании',
                  list: this.initiationStatuses.data,
                },
                {
                  type: 'textarea',
                  key: 'createRequestFirstComment4',
                  label: 'Добавить комментаий к заявке',
                },
              ],
            },
          ],
        },
        {
          tab: 'Статусы инициации',
          form: [
            {
              type: 'select',
              key: 'initiationStatuses',
              label: 'Статусы инициации',
              list: this.initiationStatuses.data,
              attributes: {
                multiselect: true,
              },
            },
          ],
        },
        {
          tab: 'Чеклист',
          form: [
            {
              type: 'text',
              key: 'checklist',
              label: 'Чеклист',
            },
          ],
        },
        {
          tab: 'Подключаемые сотрудники',
          table: {
            caption: 'Подключаемые сотрудники',
            headers: [
              {
                text: 'Сотрудник',
                order: 'name',
                alias: 'name',
              },
              {
                text: '',
                order: '',
                alias: 'actions',
              },
            ],
            modalFields: [
              {
                type: 'select',
                key: 'subscribedEmployeeId',
                label: 'Сотрудник',
                list: this.performers.data,
              },
            ],
            items: this.formattedSubscribedEmployees,
            key: 'subscribedEmployees',
            showCreateButton: true,
            showDeleteButton: true,
          },
        },
      ];

      if (!createRequest) {
        const currentGroupsArray = fields[0].groups;
        const hiddenGroupIndex = currentGroupsArray.findIndex(
          (group) => group.groupTitle === 'Поля заявки 1'
        );

        fields[0].groups.splice(hiddenGroupIndex, 1);
      }

      if (!createRequest2) {
        const currentGroupsArray = fields[0].groups;
        const hiddenGroupIndex = currentGroupsArray.findIndex(
          (group) => group.groupTitle === 'Поля заявки 2'
        );

        fields[0].groups.splice(hiddenGroupIndex, 1);
      }

      if (!createRequest3) {
        const currentGroupsArray = fields[0].groups;
        const hiddenGroupIndex = currentGroupsArray.findIndex(
          (group) => group.groupTitle === 'Поля заявки 3'
        );

        fields[0].groups.splice(hiddenGroupIndex, 1);
      }

      if (!createRequest4) {
        const currentGroupsArray = fields[0].groups;
        const hiddenGroupIndex = currentGroupsArray.findIndex(
          (group) => group.groupTitle === 'Поля заявки 4'
        );

        fields[0].groups.splice(hiddenGroupIndex, 1);
      }

      return fields;
    },

    initialContactForm() {
      return {
        id: this.form?.checklist?.length + 1,
        name: null,
        requestNumber: null,
      };
    },

    mainChecklistItems() {
      return this.form.checklist.filter((item) => item.requestNumber === null);
    },

    checklistItemsForRequest1() {
      return this.form.checklist.filter((item) => item.requestNumber === 1);
    },

    checklistItemsForRequest2() {
      return this.form.checklist.filter((item) => item.requestNumber === 2);
    },

    checklistItemsForRequest3() {
      return this.form.checklist.filter((item) => item.requestNumber === 3);
    },

    checklistItemsForRequest4() {
      return this.form.checklist.filter((item) => item.requestNumber === 4);
    },

    formattedSubscribedEmployees() {
      return (
        this.form?.subscribedEmployees?.map((item) => {
          const name =
            this.performers.data.find(
              (performer) => performer.id === item.subscribedEmployeeId
            )?.name || null;
          return { ...item, name };
        }) || []
      );
    },
  },

  created() {
    this.isLoadingPage = true;
    const gisTypes = this.getCommonList({ name: 'GisTypes' });
    const initiationStatuses = this.getCommonList({
      name: 'InitiationStatuses',
    });
    const performers = this.getCommonList({ name: 'Performers' });
    const pools = this.getCommonList({ name: 'Pools' });

    Promise.all([gisTypes, initiationStatuses, performers, pools]).then(() => {
      this.getItem({ name: this.controllerName, id: this.id })
        .then(() => {
          const data = this.lodash.cloneDeep(this.item.data);

          this.form = {
            ...data,
            initiationStatuses: data.initiationStatuses.map(
              (item) => item.statusId
            ),
          };
        })
        .finally(() => {
          this.isLoadingPage = false;
        });
    });
  },

  watch: {
    form: {
      handler() {
        this.validateForm();
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      getItem: 'editableList/getItem',
      updateItem: 'editableList/updateItem',
      getCommonList: 'common/getCommonList',
    }),

    onAddChecklist(requestNumber = null) {
      this.form.checklist.push({
        ...this.lodash.cloneDeep(this.initialContactForm),
        requestNumber,
      });
    },

    openDeleteChecklistModal(id) {
      this.$root
        .$confirmModal({
          message: 'Удалить пункт?',
          leftButtonText: 'Да',
          rightButtonText: 'Нет',
        })
        .then((response) => {
          if (response) {
            const findedItemIndex = this.form.checklist.findIndex(
              (item) => item.id === id
            );

            if (findedItemIndex !== -1) {
              this.form.checklist.splice(findedItemIndex, 1);
            }
          }
        });
    },

    validateForm() {
      const {
        moveRequestToYourself,
        choosePerformer,
        poolId,
        performerId,
        createRequestForYourself1,
        createRequestPool,
        createRequestPerformer,
        createRequestForYourself2,
        createRequestPool2,
        createRequestPerformer2,
        createRequestForYourself3,
        createRequestPool3,
        createRequestPerformer3,
        createRequestForYourself4,
        createRequestPool4,
        createRequestPerformer4,
      } = this.form;

      if ((choosePerformer || moveRequestToYourself) && (performerId || poolId)) {
        this.$set(this.form, 'performerId', null);
        this.$set(this.form, 'poolId', null);
      }

      if (
        createRequestForYourself1 &&
        (createRequestPool || createRequestPerformer)
      ) {
        this.$set(this.form, 'createRequestPerformer', null);
        this.$set(this.form, 'createRequestPool', null);
      }

      if (
        createRequestForYourself2 &&
        (createRequestPool2 || createRequestPerformer2)
      ) {
        this.$set(this.form, 'createRequestPerformer2', null);
        this.$set(this.form, 'createRequestPool2', null);
      }

      if (
        createRequestForYourself3 &&
        (createRequestPool3 || createRequestPerformer3)
      ) {
        this.$set(this.form, 'createRequestPerformer3', null);
        this.$set(this.form, 'createRequestPool3', null);
      }

      if (
        createRequestForYourself4 &&
        (createRequestPool4 || createRequestPerformer4)
      ) {
        this.$set(this.form, 'createRequestPerformer4', null);
        this.$set(this.form, 'createRequestPool4', null);
      }
    },

    onSaveForm() {
      this.$set(
        this.form,
        'initiationStatuses',
        this.form.initiationStatuses.map((statusId) => ({ statusId }))
      );

      this.onSave('SupportRoutes');
    },
  },
};
</script>

<style lang="scss">
.checklist {
  width: 100%;
}

.checklist__item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  .input__wrapper {
    margin-left: 10px;
  }
}

.checklist__delete-button {
  color: var(--gray);
  margin-left: 10px;
  &:hover {
    color: var(--red);
  }
}

.checklist__add-button {
  display: flex;
  align-items: center;
  font-weight: 500;
  line-height: 1;
  color: var(--gray);
  border: none;
  background: transparent;
  transition: color 0.3s;
  cursor: pointer;
  margin: 0 0 30px 0;
  &:hover {
    color: var(--blue);
  }
}

.support-route-edit__radio-buttons {
  margin-bottom: 20px;
}
</style>
